import React from 'react';
import Section from '../components/Section';
import Metadata from '../components/Metadata';

function iba() {
  return (
    <>
      <Metadata title='IBA' />
      <Section heading='IBA'>
        <p>
          Voraussetzung ist ein Bachelor (Experience Route) oder Master
          Abschluss (Graduate Route) in einem anerkannten Studiengang. Um die
          Zertifizierung zu erhalten, muss der Kandidat entsprechende
          Universitätskurse, 1.000 supervidierte Arbeitsstunden, Projekte
          (Experience Route), Prüfung der praktischen Fähigkeiten, ein
          mehrstündiges schriftliches Examen absolvieren sowie 12
          Fortbildungspunkte sammeln. Des Weiteren muss der IBA ein Jahr nach
          seiner ersten Zertifizierung Begleitung durch einen Mentoren erhalten
          sowie 24 Fortbildungspunkte nachweisen, um seine Zertifizierung zu
          behalten. Der IBA muss anschließend im zweijahresrhythmus
          Fortbildungspunkte nachweisen, um seine Zertifizierung zu behalten.
        </p>
        <p>
          Weitere Informationen sowie weitere Abschlüsse finden Sie unter{' '}
          <a href='https://www.theibao.com' target='_blank' rel='noreferrer'>
            www.theibao.com
          </a>
        </p>
      </Section>
    </>
  );
}

export default iba;
